import './App.css';
// import { React } from 'react';
import { React, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation, Link } from 'react-router-dom';
// import { AnimatePresence } from 'framer-motion';
import Particles from './scenes/Particles';
import Main from './pages/Main';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import About from './pages/About';

import Page from './components/Page';
import Fetch from './components/Fetch.js';
import {FetchProvider} from './components/FetchContext';
import { AnimatePresence, motion } from 'framer-motion';

function AnimatedRoutes() {

  const location = useLocation();
  const isIndexPage = location.pathname === '/';

  const [data, setData] = useState(null)
  const [loaded, setImageLoading] = useState(false)
  // const [loading, setLoading] = useState(true)

  // const preloadImages = (data) => {
  //   data.forEach((item) => {
  //     if (item.thumbnail) {
  //       const img = new Image();
  //       img.src = item.thumbnail;
  //       // console.log(`Preloading: ${item.thumbnail}`);
  //     }
  //   });
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await Fetch();
        setData(fetchedData); // Set the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setLoading(false); // Stop loading once the data is fetched
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const preloadImages = (data) => {
      const promises = data.map((src) => preloadImage(src.thumbnail));
      return Promise.all(promises);
    }
    const preloadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    };
    if(data){
      preloadImages(data).then(() => {
        setImageLoading(true)
      });
    }
  }, [data]);

  

  return (
  // !loading ?
  // (
    <>
    {/* <div className='fixed w-screen h-screen -z-20'>  , transform:`translateY(${pos(location.pathname)}%) scale(${(isIndexPage ? '1' : '1.3')})`*/} 
      <div className='absolute w-full h-full transition-all ease-out duration-1000' style={{filter:`blur(${(loaded ? (isIndexPage ? '0.5' : '10') : '5')}px)`,scale:(loaded ? '1' : '1.1')}}>
        <Particles color='white' newposition={(isIndexPage ? '0' : '-20')}/>
      </div>
    {/* </div> */}

    {/* <div className='fixed bg-black/50 backdrop-blur-sm h-screen w-screen transition-opacity duration-[2000ms]' style={{opacity:(data ? '0' : '1'),pointerEvents:(data ? 'none' : 'auto')}}/> */}

    <FetchProvider fetch={data}>
        <AnimatePresence mode='wait'>
            <motion.div
              key={loaded}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              {loaded ?
              <>
                <Nav />
                <AnimatePresence mode="wait">
                  <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Page><Main /></Page>} />
                    <Route path="/about" element={
                      <Page>
                        <ContentContainer>
                          <About />
                        </ContentContainer>
                      </Page>
                    } />
                    <Route path="/*" element={
                      <Page>
                        <div className="flex  w-full h-screen text-white justify-end items-center p-12">
                          <svg fill="none" viewBox="0 0 24 24" strokeLinejoin="1.5" stroke="currentColor" className='size-8 mr-4'>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                          </svg>
                          <Link to='/' className='text-2xl  lg:text-5xl origin-right font-thin italic tracking-normal hover:tracking-wide opacity-100 hover:opacity-50 transition-all'>
                            Page not found. Click here to return
                          </Link>
                        </div>
                      </Page>
                    } />
                    <Route path="/projects" element={
                      <Page>
                        <ContentContainer>
                          <Projects />
                        </ContentContainer>
                      </Page>
                    } />
                    <Route path="/contact" element={
                      <Page>
                        <ContentContainer>
                          <Contact />
                        </ContentContainer>
                      </Page>
                    } />
                    {/* <Route path="/about" element={<About />} /> */}
                    {/* <Route path="/contact" element={<Contact />} /> */}
                  </Routes>
                </AnimatePresence>
              </>
              : 
              // <div className='absolute top-0 h-full w-full bg-black/50'>
                // loading animation
                <div className='bottom-24 fixed right-12 lg:right-24 text-white'>
                  <svg
                  className='animate-spin w-8 h-8 lg:w-20 lg:h-20'
                    viewBox="0 0 1024 1024"
                    fill="currentColor"
                  >
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
                  </svg>
                </div>
              // </div>
              }
            </motion.div>
        </AnimatePresence>
      </FetchProvider>

    </>
  )
  // :
  // (<></>));
}

function ContentContainer ({children}){
  return(
    <div className='w-screen h-screen flex items-end justify-end'>
      <div className='p-4 lg:p-16 w-full h-[calc(100vh-96px)] lg:h-full lg:w-[calc(100vw-192px)]'>
        {children}
      </div>
    </div>
  )
}

function Nav() {
  const location = useLocation();
  
  const pages = [
    "contact",
    "projects",
    "about",
  ]
  
  const isIndexPage = !pages.map(page => `/${page}`).includes(location.pathname);
  // const pos = (value) => ({
  //   '/about': "0",
  //   '/projects': "50",
  //   '/contact': "00",
  // }[value] || "0")
  
  return(
    <>
      <div className='w-full fixed flex justify-center lg:hidden px-2 py-8 z-[999]' style={{transform: `translateY(${(isIndexPage ? '-15' : '0')}rem)`, transition: 'transform 1.5s ease-in-out'}}>
        <div className='grid grid-cols-3 items-center justify-center text-sm gap-x-2 text-white font-semibold tracking-widest font-main relative' >
          {pages.toReversed().map((item, index) => (
            <Link className={`capitalize relative col-span-1 text-center py-1 w-28 rounded-full bg-white ${location.pathname === `/${item}` ? '!bg-opacity-100 text-black' : 'hover:bg-opacity-30 bg-opacity-0'}  cursor-pointer transition-all duration-300 relative z-10`} key={index} to={`/${item}`}>
                {/* <span className='absolute group-hover:opacity-100 opacity-0 transition-opacity mx-1'>•</span> */}
                <span>{item}</span> 
            </Link>
          ))}
        </div>
      </div>
      <div className='absolute w-[95vh] bottom-0 left-20 hidden lg:flex items-center justify-between px-12 z-[999] origin-left' style={{transform: `rotate(-90deg)  translateY(${(isIndexPage ? '-15' : '0')}rem)`, transition: 'transform 1.5s ease-in-out'}}>
        <span className='text-white/50 text-lg font-light tracking-widest font-main'>© {(new Date().getFullYear())}</span> 

        <div className='h-[1px] bg-white/10 grow mx-8' />

        <div className='grid grid-cols-3 items-center justify-center gap-x-4 text-white text-lg font-semibold tracking-widest font-main relative'>
          {pages.map((item, index) => (
            <Link className={`capitalize relative col-span-1 text-center py-1 w-32 rounded-full bg-white ${location.pathname === `/${item}` ? '!bg-opacity-0' : 'hover:bg-opacity-30 bg-opacity-0'}  cursor-pointer transition-all duration-300 relative z-10`} key={index} to={`/${item}`}>
                {/* <span className='absolute group-hover:opacity-100 opacity-0 transition-opacity mx-1'>•</span> */}
                <span>{item}</span> 
            </Link>
          ))}
          <div className='absolute rounded-full bg-black h-8 w-32 z-0 transition-all duration-500 ease-out' style={{
            left:`${({ '/about': "100", '/projects': "50", '/contact': "0", }[location.pathname] || "100")}%`,
            transform:`translateX(${({ '/about': "-100", '/projects': "-50", '/contact': "0", }[location.pathname] || "-100")}%)`,
            transitionDelay:`${(isIndexPage ? '1000ms' : '0s')}`
            // opacity:`${(isIndexPage ? '0' : '1')}`
            }}/> 
          <div className='absolute rounded-full backdrop-invert h-8 w-32 z-20 transition-all duration-500 ease-out' style={{
            left:`${({ '/about': "100", '/projects': "50", '/contact': "0", }[location.pathname] || "100")}%`,
            transform:`translateX(${({ '/about': "-100", '/projects': "-50", '/contact': "0", }[location.pathname] || "-100")}%)`,
            transitionDelay:`${(isIndexPage ? '1000ms' : '0s')}`
            }} />
        </div>
        {/* <div className='grid grid-cols-3 items-center justify-center gap-x-4 text-white text-lg font-semibold tracking-widest font-main'>
          {pages.map((item, index) => (
            <Link className={`capitalize col-span-1 text-center py-1 px-6 rounded-full bg-white ${location.pathname === `/${item}` ? '!bg-opacity-100' : 'bg-opacity-0'} hover:bg-opacity-30 cursor-pointer transition-all duration-300 relative`} key={index} to={`/${item}`}>
                <span className={`${location.pathname === `/${item}` ? '!text-black' : ''} transition-all`}>{item}</span> 
            </Link>
          ))}
        </div> */}

        <div className='h-[1px] bg-white/10 grow mx-8' />

        <Link to='/' className='size-9 rounded-full bg-white bg-opacity-0 hover:bg-opacity-30 text-white flex items-center justify-center transition-all cursor-pointer'>
          <svg fill="none" viewBox="0 0 24 24" strokeLinejoin="1.5" stroke="currentColor" className='rotate-90 size-5 '>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
          </svg>
        </Link>
        {/* <div className='size-8 rounded-full outline-4 outline-white/30 bg-white flex items-center justify-center'>
          <svg className='absolute rotate-90' style={{display:'none'}} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278"/>
          </svg>
          <svg className='absolute' width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/>
          </svg>
        </div> */}
      </div>
    </>
  )
}

function App() {
  useEffect(() => {
    if (window.location.pathname === '/commission') {
      window.location.href = 'https://forms.gle/WDhTvcBrP3o8tzqUA'; // Use navigate('/new-path') for internal navigation
    }
  }, []);

  return (
    <BrowserRouter>
      <div
        id='background' className='w-screen h-screen overflow-hidden'
      > 
        <AnimatedRoutes />
      </div>
    </BrowserRouter>
  );
}

export default App;

