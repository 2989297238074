// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
// import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";

import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export Firebase services to use in your app

// Initialize Firebase Auth
const auth = getAuth(app); // Pass the initialized app to getAuth

// Sign in anonymously
export const signInAnon = async () => {
  try {
    const userCredential = await signInAnonymously(auth);
    const user = userCredential.user;

    const token = await user.getIdToken();
    // console.log('Anonymous ID Token:', token);

    return token; // This token can be used for making authenticated API requests
  } catch (error) {
    console.error("Authentication error:", error);
  }
};

export const firestore = getFirestore(app);